import React from "react";
import robot from "./Assets/icons/robot.svg";
import talk from "./Assets/icons/talk.svg";
import puzzle from "./Assets/icons/puzzle.svg";
import mind from "./Assets/icons/mind.svg";

export default {
    nav: {
        why: "Неліктен робототехника?",
        us: "Неге біз?",
        start: "Қалай бастауға болады?",
        events: "Іс-шаралар",
        acknowledgements: "Қолдаушылар",
        home: 'Басты бет',
    },
    hero: {
        h1: (<>Робототехника&nbsp;&mdash; болашақ бағдары</>),
        p: "Өзіңіздің робототехникадағы мансабыңызды сізді таңқаларлық роботтарды жасауға үйрететін кешенді курстан бастаңыз",
    },
    why: {
        h1: "Неліктен робототехника?",
        items: [
            {
                icon: robot,
                title: "Жаңа ғылым",
                text: `Робототехника - ғарыштап дамытуға көмектесетін адамдарға мұқтаж жаңа ғылым саласы. Басқаша айтқанда, жақын арада робототехника мамандары үлкен сұранысқа ие болады`
            },
            {
                icon: talk,
                title: "Әсері",
                text: `Робототехника кез-келген ғылым саласымен сыбайласа отырып, практикалық мәнге ие бола алады. Оның арқасында сіз айналаңыздағы адамдардың күнделікті өмірін өзгерте алатын заттарды жасай аласыз`
            },
            {
                icon: puzzle,
                title: "Soft skills",
                text: `Робототехника сізге коммуникациялық және ұсына білу дағдыларын дамытуға тамаша мүмкіндік береді. Сонымен қатар, сіз роботты жалғыз жасай алмайсыз және оны жақсы ұсына білуіңіз керек.`
            },
            {
                icon: mind,
                title: "Ақыл және қол",
                text: `Шыны керек, металл қолды жылтыратудан бастап, нейрондық желі жазуға дейін жалпы интеллектуалды және физикалық жұмыспен айналысуға шамамен бірдей уақыт жұмсауды талап ететін кез-келген басқа пәнді табу қиын.`
            }
        ]
    },
    whyus: {
        h1: "Неге біз?",
        h3_1: "100% тегін",
        p1: "Ешқандай жасырын төлемдер не жазылымдар жоқ",
        h3_2: "Бірегей ресурстар",
        p2: "Біз бұған дейін орыс / қазақ ғаламторларында таралмаған білімді сізге табыстаймыз.",
        h3_3: "Сарапшылардан үйреніңіз",
        p3: "Олар Қазақстанның туын бірінші болып WRO-да көтерген, әрі көптеген рекордты жарған марапаттарға ие болды.",
        h3_4: "Қазақ тіліндегі контент",
        p4: "Біздің сабақтарымыз орыс тілінде болса да, біз сізге қазақ тіліндегі субтитрлермен оларды тамашалауға мүмкіндік береміз.",
    },
    team: {
        h1: "Біздің команда",
    },
    start: {
        h1: "Қалай бастауға болады?",
        h3: "Америкалық бұрыштар",
        li1: (<>Теорияны осы сілтеме бойынша меңгеріңіз <a href="https://school.bc-pf.org">Мектеп</a></>),
        li2: "Жергілікті Америкалық бұрышты немесе Өзің жаса (Makerspace) лабороториясына барыңыз",
        li3: "Америкалық бүрыштардағы тәлімгерлерден көмек сұраңыз",
        cities1: ["Нұр-Сұлтан", "Павлодар", "Петропавловск", "Өскемен", "Қарағанды"],
        cities2: ["Алматы", "Шымкент", "Ақтөбе", "Атырау", "Орал"],
    },
    timeline: {
        h1: "Теориялық модульдер",
    },
    webinars: {
        h1: "Вебинарлар",
        about: "Спикер туралы",
        topic: "Тақырыбы",
        but: "Тіркелу",
        connect: "Вебинарға қосылу",
        watch: "Вебинардың бейнежазбасы"
    },
    posts: {
        h1: "Блог жазбалары",
        empty: "Жаңартулар үшін осы парақшаға кейін шолу жасаңыз!  "
    },
    wiki: {
        h1: "Wiki-дағы \"Қалай?\" атты нұсқаулықтары",
        empty: "Жаңартулар үшін осы парақшаға кейін шолу жасаңыз! :)"
    },
    footer: {
        h1: "Алғыс сөз",
        funding: "Бұл жоба «Шеврон» ЖШС мен АҚШ-тың Қазақстандағы елшілігінің жеке мемлекеттік серіктестігі шеңберінде қаржыландырылды. "
    } 

}