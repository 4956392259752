import classes from "./styles.module.css";
import useInView from "react-cool-inview";
import beyond from "../../Assets/beyond.svg";
import { useState, useContext } from "react";
import clsx from "clsx";
import locale from "../../locale.jsx";
import LangContext from "../../Utils/Lang";

const Us = () => {
    const lang = useContext(LangContext);
    const l = locale[lang];
    const [show, setShowed] = useState(false)
    const { ref } = useInView(
        {
            threshold: 0.25, // Default is 0
            onEnter: () => {
                setShowed(true)
            },
        }
    );
    return (
        <div ref={ref} className={clsx(classes.us, {
            [classes.show]: show
        })}>
            <div className="anchor" id="us" />
            <div className="container">
                <h1>{l.whyus.h1}</h1>
                <div className={classes.flex}>
                    <div className={classes.column}>
                        <div className={classes.item}>
                            <h3>{l.whyus.h3_1}</h3>
                            <p>{l.whyus.p1}</p>
                        </div>
                        <div className={classes.item}>
                            <h3>{l.whyus.h3_2}</h3>
                            <p>{l.whyus.p2}</p>
                        </div>
                    </div>
                    <div className={classes.logo}>
                        <img src={beyond} alt="BeyondCurriculum" />
                    </div>
                    <div className={classes.column}>
                        <div className={classes.item}>
                            <h3>{l.whyus.h3_3}</h3>
                            <p>{l.whyus.p3}</p>
                        </div>
                        <div className={classes.item}>
                            <h3>{l.whyus.h3_4}</h3>
                            <p>{l.whyus.p4}</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Us;