import classes from "./styles.module.css";
import { useContext } from "react";
import locale from "../../locale.jsx";
import LangContext from "../../Utils/Lang";

const Start = () => {
    const lang = useContext(LangContext);
    const l = locale[lang];
    return (
        <div className={classes.start}>
            <div className="anchor" id="start" />
            <div className="container">
                <h1>{l.start.h1}</h1>
                <div className={classes.grid}>
                    <ol className={classes.steps}>
                        <li key="1">{l.start.li1}</li>
                        <li key="2">{l.start.li2}</li>
                        <li key="3">{l.start.li3}</li>
                    </ol>
                    <div className={classes.citiesWrap}>
                        <a href="http://www.amcorners.kz"><h3><span>{l.start.h3}</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><g fill="none" fillRule="evenodd"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8" /></g></svg></h3></a>
                        <div className={classes.cities}>
                            <ul>
                                {
                                    l.start.cities1.map((el) => (
                                        <li key={el}>{el}</li>
                                    ))
                                }
                            </ul>
                            <ul>
                                {
                                    l.start.cities2.map((el) => (
                                        <li key={el}>{el}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Start;