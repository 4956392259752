import { useEffect, useState, useContext } from "react";
import Loader from "../Loader";
import Modal from "../Modal";
import classes from "./styles.module.css";
import locale from "../../locale.jsx";
import LangContext from "../../Utils/Lang";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import React from 'react'

const Timeline = () => {
    const { trackEvent } = useMatomo()
    const lang = useContext(LangContext);
    const l = locale[lang];

    const [data, setData] = useState({
        isFetching: true,
        items: null,
        start: "",
        end: ""
    })
    const [modal, setModal] = useState({
        open: false,
        title: "",
        content: ""
    })
    useEffect(() => {
        fetch(`/data/timeline.json?_dc=${(+(new Date()))}`)
            .then(res => res.json())
            .then(json => {
                setData({
                    isFetching: false,
                    ...json
                })
            })
    }, [])
    const locData = data[lang] || {};
    return (
        <div className={classes.timeline} id="timeline">
            <div className="container">
                <h1>{l.timeline.h1}</h1>
                {locData.isFetching ? <Loader /> : (
                    <div className={classes.timeline}>
                        <div className={classes.date}>{locData.start ? locData.start : null}</div>
                        <div className={classes.items}>
                            {locData.items ? locData.items.map((el) => (
                                <div onClick={() => (
                                    trackEvent({ category: 'timeline', action: 'open-modal', name: el.title }),
                                    setModal({
                                    open: true,
                                    title: el.title,
                                    content: el.lessons,
                                    context: el.context
                                }))} key={el.title} className={classes.item}>
                                    <div className={classes.title}><h3>{el.title}</h3></div>
                                    <div className={classes.details}>
                                        <span>{el.dates}</span>
                                        {el.text ? <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                                        </button> : null}
                                    </div>
                                </div>
                            )) : null }
                        </div>
                        <div className={classes.date}>{locData.end ? locData.end : null}</div>
                    </div>
                )}
            </div>
            <Modal open={modal.open} close={() => setModal({ ...modal, open: false })} title={modal.title}>
                {/* {modal.content.split("\n").map((el, i) => (
                    <p key={i}>{el}</p>
                ))} */}
                { modal.context }
                <br/> <br />
                { modal.content ? 
                    modal.content.map((el, i) => (
                        <p key={i}>{el.title.split(".")[0]}. <a className={el.href ? classes.href : null} href={el.href ? el.href : null}>{el.title.split(".")[1]}</a> </p>
                    ))
                : null }
            </Modal>
        </div>
    )
}

export default Timeline;