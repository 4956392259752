import "./App.css";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

import Navbar from "./Components/Navbar";

import Footer from "./Components/Footer";
import { LangProvider, langsMap } from "./Utils/Lang";
import { useState } from "react";
import Index from "./Pages/Index";
import WebinarPage from "./Pages/Webinar";


const findGetParameter = (parameterName) => {
  let result = null,
    tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach((item) => {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
};

const getLang = () => {
  const lang = localStorage.getItem("lang")
  return ["ru", "en", "kk"].indexOf(lang) > -1 ? lang : null
}

const App = () => {
  const instance = createInstance({
    urlBase: 'https://analytics.bc-pf.org/',
    siteId: 10,
    // userId: 'UID76903202', // optional, default value: `undefined`.
    // trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
    // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    // heartBeat: { // optional, enabled by default
    //   active: true, // optional, default value: true
    //   seconds: 10 // optional, default value: `15
    // },
    // linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
      // any valid matomo configuration, all below are optional
      disableCookies: true,
      setSecureCookie: true,
      // setRequestMethod: 'POST'
    }
  })
  const [lang, updateLang] = useState(langsMap[findGetParameter("lang")] || getLang() || "ru");
  const setLang = (lang) => {
    localStorage.setItem("lang", lang)
    updateLang(lang);
  }
    return (
    <MatomoProvider value={instance}>
      <Router>
        <LangProvider value={lang}>
          <Navbar setLang={setLang} />
            <Switch>
              <Route path="/" exact component={Index}/>
              <Route path="/webinar/:index" component={WebinarPage}/>
            </Switch>
          <Footer />
        </LangProvider>
      </Router>
    </MatomoProvider>
  )
}

export default App;