import {Link} from "react-router-dom"
import classes from "./styles.module.css";

const Person = ({ photo, name, title, date, href, recording, l, index}) => (
    <div className={classes.person}>
        <div style={{
            backgroundImage: `url(${photo})`
        }} className={classes.personPhoto} />
        <h3>{name}</h3>
        <p>{title}</p>
        {date ? <p className={classes.date}>{date}</p> : null}
        {href ? <Link to={`/webinar/${index}`}><button className={classes.button}>{l.webinars.but}</button></Link> : null }
        {recording ? <div className={classes.watch}><a href={recording}>{l.webinars.watch}</a></div> : null }
    </div>
)

export default Person;