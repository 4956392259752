import React from "react";
import robot from "./Assets/icons/robot.svg";
import talk from "./Assets/icons/talk.svg";
import puzzle from "./Assets/icons/puzzle.svg";
import mind from "./Assets/icons/mind.svg";

export default {
    nav: {
        why: "Why robotics?",
        us: "Why us?",
        start: "How to start?",
        events: "Events",
        acknowledgements: "Acknowledgements",
        home: 'Main page',
    },
    hero: {
        h1: "The future is in robotics",
        p: "Start your career in Robotics with our comprehensive course that will teach you how to build awe-inspiring robots",
    },
    why: {
        h1: "Why Robotics?",
        items: [
            {
                icon: robot,
                title: "New Science",
                text: `Robotics is a just-emerging science that needs people who would innovate it further. In other words, specialists in Robotics will be in huge demand in the foreseeable future`
            },
            {
                icon: talk,
                title: "Impact",
                text: `Robotics is as practical as any science can get. You literally create things that can change daily lives of people around you.`
            },
            {
                icon: puzzle,
                title: "Soft Skills",
                text: `Robotics provides an excellent opportunity to develop your communication and presentation skills. After all, you can’t build a robot alone and you definitely need to present it well.`
            },
            {
                icon: mind,
                title: "Mind & Hand",
                text: `It’s hard to find any other discipline in which you’ll spend almost equal amount of time doing intellectual and physical work: from polishing a metal arm to writing a neural network.`
            }
        ]
    },
    whyus: {
        h1: "Why us?",
        h3_1: "100% free",
        p1: "No hidden fees, no subscription.",
        h3_2: "Unique material",
        p2: "We’ll bring knowledge previously unknown in the Russian/Kazakh internet.",
        h3_3: "Learn from experts",
        p3: "who were the first from Kazakhstan to participate at WRO and won numerous record-breaking awards.",
        h3_4: "Kazakh-friendly",
        p4: "Our lessons are in Russian with Kazakh subtitles.",
    },
    team: {
        h1: "Our team",
    },
    start: {
        h1: "How to start?",
        h3: "American Corners",
        li1: (<>Watch theory in our <a href="https://school.bc-pf.org">School</a></>),
        li2: "Visit local American Corner or Maker Space",
        li3: "Ask for help from Mentors at American Corners",
        cities1: ["Astana", "Pavlodar", "Petropavlovsk", "Oskemen", "Karaganda"],
        cities2: ["Almaty", "Shymkent", "Aktobe", "Atyrau", "Oral"],
    },
    timeline: {
        h1: "Theory Modules",
    },
    webinars: {
        h1: "Webinars",
        about: "About speaker",
        topic: "Topic",
        but: "RSVP",
        connect: "Connect to a webinar",
        watch: "Webinar recording"
    },
    posts: {
        h1: "Blog posts",
        empty: "Check out this page later for updates! "
    },
    wiki: {
        h1: "How-to Guides on Wiki",
        empty: "Check out this page later for updates! :)"
    },
    footer: {
        h1: "Acknowledgements",
        funding: "This project was funded as part of the Private Public Partnership of Chevron LLC and US Embassy in Kazakhstan. "
    } 

}