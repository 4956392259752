import classes from './styles.module.css';
import ac from "../../Assets/ac.svg";
import uskz from "../../Assets/uskz.svg";
import chevron from "../../Assets/chevron.png";
import makerspace from "../../Assets/makerspace.svg";
import locale from "../../locale.jsx";
import LangContext from "../../Utils/Lang";
import { useContext } from "react";

const logos = [
    {
        img: makerspace,
        title: "Makerspace Expands!"
    },
    {
        img: uskz,
        title: "US Embassy in Kazakhstan"
    },
    {
        img: ac,
        title: "American Corner Kazakhstan",
        href: "http://www.amcorners.kz"
    },
    {
        img: chevron,
        title: "Chevron LLC"
    }
]

const Acknowledgements = () => {
    const lang = useContext(LangContext);
    const l = locale[lang];
    return (
        <div className={classes.acknowledgements}>
            <div className="anchor" id="acknowledgements" />
            <div className="container">
                <h1>{l.footer.h1}</h1>
                <div className={classes.logos}>
                    {logos.map((el, i) => (
                        <img key={i} src={el.img} alt={el.title} />
                    ))}
                </div>
                <div className={classes.text}>{l.footer.funding}</div>
            </div>
        </div>
    );
}

export default Acknowledgements;