import clsx from "clsx";
import { useContext, useEffect, useState } from "react"
import { Redirect } from "react-router";
import Loader from "../Components/Loader";
import locale from "../locale";
import LangContext from "../Utils/Lang";
import classes from './Webinar.module.css';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import React from 'react'

const WebinarPage = ({match}) => {
    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
      trackPageView()
    }, [])
    const {index} = match.params
    const lang = useContext(LangContext);
    const l = locale[lang];
    const [data, setData] = useState({
        isFetching: true,
        items: []
    });
    useEffect(() => {
        fetch(`/data/webinars.json?_dc=${(+(new Date()))}`)
            .then(res => res.json())
            .then(json => {
                setData({
                    isFetching: false,
                    items: json[lang].items
                })
            })
    }, [])
    if(data.isFetching){
        return <Loader/>
    }
    const person = data.items[index - 1];
    if(!person){
        return <Redirect to="/"/>
    }
    return (
        <div className={classes.page}>
            <div className="container">
                <div style={{
                    backgroundImage: `url(${person.photo})`
                }} className={classes.photo} />
                <h2>{person.name}</h2>
                <p>{person.title}</p>
                <h3>{l.webinars.about}</h3>
                <p dangerouslySetInnerHTML={{ __html: person.bio }}></p>
                <h3>{l.webinars.topic}</h3>
                <p dangerouslySetInnerHTML={{ __html: person.topic }}></p>
                <p className={classes.dates}>{person.date}</p>
                <div className={classes.buttons}>
                <a className={clsx({
                        [classes.button]: true,
                        [classes.disabled]: !person.href
                    })} href={person.href}>{l.webinars.but}</a>
                <a className={clsx({
                        [classes.button]: true,
                        [classes.disabled]: !person.link
                    })} href={person.link}>{l.webinars.connect}</a>
                </div>
            </div>
        </div>
    )
}

export default WebinarPage