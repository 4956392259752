import { useEffect, useState, useContext } from "react";
import Loader from "../Loader";
import Person from "../Person";
import classes from "./styles.module.css";
import locale from "../../locale.jsx";
import LangContext from "../../Utils/Lang";
import Modal from "../Modal";

const Webinars = () => {
    const lang = useContext(LangContext);
    const l = locale[lang];
    const [data, setData] = useState({
        isFetching: false,
        items: []
    })
    const [modal, setModal] = useState({
        open: false,
        title: "",
        content: ""
    })
    useEffect(() => {
        fetch(`/data/webinars.json?_dc=${(+(new Date()))}`)
            .then(res => res.json())
            .then(json => {
                setData({
                    isFetching: false,
                    ...json
                })
            })
    }, []);
    const locData = data[lang] || {};
    return (
        <div className={classes.webinars}>
            <div className="anchor" id="events" />
            <div className="container">
                <h1>{l.webinars.h1}</h1>
                {
                    locData.isFetching ? <Loader /> : (
                        <div className={classes.items}>
                            {locData.items ? locData.items.map((el, i) => (
                                    <div key={i} className={classes.person}>
                                        <Person index={i+1} {...el} l={l} />
                                    </div>
                            )) : null}
                        </div>
                    )
                }
            </div>
            <Modal open={modal.open} close={() => setModal({ ...modal, open: false })} title={modal.title}>
                <a href={modal.rsvp}>Зарегистрироваться</a>
            </Modal>
        </div>
    )
}

export default Webinars