import { useEffect, useState, useContext } from "react";
import classes from "./styles.module.css";
import Loader from "../Loader"
import Person from "../Person"
import locale from "../../locale.jsx";
import LangContext from "../../Utils/Lang";

const Team = () => {
    const lang = useContext(LangContext);
    const l = locale[lang]
    const [data, setData] = useState({
        isFetching: true,
    });
    useEffect(() => {
        fetch(`/data/team.json?_dc=${(+(new Date()))}`)
            .then(res => res.json())
            .then(json => {
                setData({
                    isFetching: false,
                    ...json
                })
            })
    }, []);
    const locData = data[lang] || {} ;
    return (
        <div className={classes.team}>
            <div className="container">
                <h1>{l.team.h1}</h1>
                {locData.head ? (
                    <div className={classes.head}>
                        <Person {...locData.head} />
                        <div className={classes.text}>
                            {locData.head.text.split("\n").map((el, i) => (
                                <p key={i}>{el}</p>
                            ))}
                        </div>
                    </div>
                ) : null}
                {locData.isFetching ? <Loader /> : (
                    <div className={classes.members}>
                        {
                            locData.team ? locData.team.map((el, i) => (
                                <Person key={i} {...el} />
                            )) : null
                        }
                    </div>
                )}
            </div>
        </div>
    )
}

export default Team;