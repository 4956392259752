import classes from "./styles.module.css";

const Post = ({ href, image, title }) => (
    <a className={classes.post} href={href}>
        <div className={classes.imageWrap}>
            <img src={image} alt={title} />
        </div>
        <h3>{title}</h3>
    </a>
)

export default Post;