import classes from "./styles.module.css";
import { useState, useContext } from "react";
import useInView from "react-cool-inview";
import clsx from "clsx";
import locale from "../../locale.jsx";
import LangContext from "../../Utils/Lang";

const Why = () => {
    const lang = useContext(LangContext);
    const l = locale[lang];
    const [show, setShowed] = useState(false)
    const { ref } = useInView(
        {
            threshold: 0.25, // Default is 0
            onEnter: () => {
                setShowed(true)
            },
        }
    );
    return (
        <div ref={ref} className={clsx(classes.why, {
            [classes.show]: show
        })}>
            <div className="anchor" id="why" />
            <div className="container">
                <h1>{l.why.h1}</h1>
                <div className={classes.items}>
                    {l.why.items.map((el, i) => (
                        <div key={el.title} className={classes.item}>
                            <img style={{
                                transitionDelay: (0.2 + i * 0.5) + 's'
                            }} src={el.icon} alt={el.title} />
                            <h3 style={{
                                transitionDelay: (0.5 + i * 0.5) + 's'
                            }}>{el.title}</h3>
                            <p style={{
                                transitionDelay: (0.8 + i * 0.5) + 's'
                            }}>{el.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Why;