import { useEffect, useState, useContext } from 'react';
import Loader from '../Loader';
import Post from '../Post';
import classes from './styles.module.css'
import locale from "../../locale.jsx";
import LangContext from "../../Utils/Lang";

const Posts = () => {
    const lang = useContext(LangContext);
    const l = locale[lang];
    const [data, setData] = useState({
        isFetching: true,
        posts: []
    });
    useEffect(() => {
        fetch(`/data/posts.json?_dc=${(+(new Date()))}`)
            .then(res => res.json())
            .then(json => {
                setData({
                    isFetching: false,
                    ...json
                })
            })
    }, []);
    const locData = data[lang] || {};
    return (
        <div className={classes.posts}>
            <div className="container">
                <h1>{l.posts.h1}</h1>
                {
                    locData.isFetching ? <Loader /> : (
                        locData.posts ? locData.posts.length ? (
                            <div className={classes.items}>{
                                locData.posts.map((el, i) => (
                                    <Post {...el} key={i} />
                                ))
                            }</div>
                        ) : <div className={classes.empty}>{l.posts.empty}</div> : null
                    )
                }
            </div>
        </div>
    );
}

export default Posts;