import classes from './styles.module.css';
import locale from "../../locale.jsx";
import LangContext from "../../Utils/Lang";
import { useContext } from "react";


const Footer = () => {
    const lang = useContext(LangContext);
    const l = locale[lang];
    return (
        <div className={classes.acknowledgements}>
            <div className="anchor" id="acknowledgements" />
            <div className="container">
                <div className={classes.text}>&copy;&nbsp;2021-{new Date().getFullYear()} <a href="https://bc-pf.org/">Общественный Фонд &laquo;Beyond Curriculum&raquo;</a> <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode">(CC BY-NC-SA 4.0 International)</a></div>
            </div>
        </div>
    );
}

export default Footer;