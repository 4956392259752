import classes from "./styles.module.css";
import image from "../../Assets/hero.svg";
import useInView from "react-cool-inview";
import { useState, useContext } from "react";
import clsx from "clsx";
import locale from "../../locale.jsx";
import LangContext from "../../Utils/Lang";

const Hero = () => {
    const lang = useContext(LangContext);
    const l = locale[lang]
    const [show, setShowed] = useState(false)
    const { ref } = useInView(
        {
            threshold: 0.25, // Default is 0
            onEnter: () => {
                setShowed(true)
            },
        }
    );
    return (
        <div ref={ref} className={clsx(classes.hero, {
            [classes.show]: show
        })}>
            <div className="container">
                <div className={classes.grid}>
                    <div className={classes.text}>
                        <h1>{l.hero.h1}</h1>
                        <p>{/*So what are you waiting for? <br />*/} {l.hero.p}</p>
                    </div>
                    <div className={classes.image}>
                        <img src={image} alt="Robotics" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;