import Hero from "../Components/Hero";
import Why from "../Components/Why";
import Us from "../Components/Us";
import Team from "../Components/Team";
import Start from "../Components/Start";
import Timeline from "../Components/Timeline";
import Webinars from "../Components/Webinars";
import Posts from "../Components/Posts";
import Wiki from "../Components/Wiki";
import Acknowledgements from "../Components/Acknowledgements";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import React from 'react'

const Index = () => {
    const { trackPageView } = useMatomo()

    // Track page view
    React.useEffect(() => {
      trackPageView()
    }, [])
    return (
    <>
        <Hero />
        <Why />
        <Us />
        <Team />
        <Start />
        <Timeline />
        <Webinars />
        <Posts />
        <Wiki />
        <Acknowledgements />
    </>
)
}

export default Index;