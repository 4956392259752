import localeEn from "./localeEn";
import localeRu from "./localeRu";
import localeKk from "./localeKk";

const locale = {
  ru: localeRu,
  en: localeEn,
  kk: localeKk,
};

export default locale;
