import classes from "./styles.module.css";
import ac from "../../Assets/ac.svg";
import chevron from "../../Assets/chevron.png";
import beyond from "../../Assets/beyond.svg";
import uskz from "../../Assets/uskz.svg";
import makerspace from "../../Assets/makerspace.svg";
import { useState, useContext } from "react";
import {Link, useLocation} from 'react-router-dom'
import clsx from "clsx";
import locale from "../../locale.jsx";
import LangContext, { langsMap } from "../../Utils/Lang";

const langs = ["ru", "en", "kk"];

const logos = [
    {
        img: beyond,
        title: "BeyondCurriculum",
        href: "https://beyondcurriculum.kz/"
    },
    {
        img: makerspace,
        title: "Makerspace Expands!"
    },
    {
        img: uskz,
        title: "US Embassy in Kazakhstan"
    },
    {
        img: ac,
        title: "American Corner Kazakhstan",
        href: "http://www.amcorners.kz"
    },
    {
        img: chevron,
        title: "Chevron LLC"
    }
]

const Navbar = ( {setLang} ) => {
    const [langMenu, setLangMenu] = useState(false)
    const lang = useContext(LangContext);
    const l = locale[lang];
    const [open, setOpen] = useState(false);
    const location = useLocation()
    return (
        <nav className={clsx(classes.nav, {
            [classes.open]: open
        })}>
            <div className="container">
                <div className={classes.links}>
                <p className={classes.logo}><span className={classes.logoBold}>Beyond</span>Robotics</p>
                    {location.pathname === "/" ? (<><a href="/#why">{l.nav.why}</a>
                    <a href="/#us">{l.nav.us}</a>
                    <a href="/#start">{l.nav.start}</a>
                    <a href="/#events">{l.nav.events}</a>
                    <a href="/#acknowledgements">{l.nav.acknowledgements}</a></>) : (
                        <Link style={{
                            display: 'flex',
                            alignItems: 'center'
                        }} to="/">
                            <svg style={{
                                display: 'block',
                                marginRight: 8
                            }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><path d="M19 12H6M12 5l-7 7 7 7"/></svg>
                            <span>{l.nav.home}</span>
                        </Link>
                    )}
                </div>
                <div className={classes.langWrapper}>
                    <div
                        onClick={() => {
                        setLangMenu(!langMenu);
                        //setLang(getNext(langs, lang));
                        }}
                        className={classes.lang}
                        >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        fill="currentColor"
                        viewBox="0 0 512 512"
                        >
                            <title>{lang}</title>
                            <path d="M267,474l-.8-.13A.85.85,0,0,0,267,474Z" />
                            <path d="M448.9,187.78a5.51,5.51,0,0,0-10.67-.63h0A5.52,5.52,0,0,1,433,191H417.53a5.48,5.48,0,0,1-2.84-.79l-22.38-13.42a5.48,5.48,0,0,0-2.84-.79h-35.8a5.48,5.48,0,0,0-3.06.93l-44.15,29.43A5.52,5.52,0,0,0,304,211v41.74a5.51,5.51,0,0,0,2.92,4.87l57.89,30.9a5.55,5.55,0,0,1,2.92,4.8L368,316.8a5.53,5.53,0,0,0,2.85,4.75l23.26,12.87a5.54,5.54,0,0,1,2.85,4.83v48.6a5.52,5.52,0,0,0,9.17,4.14c9.38-8.26,22.83-20.32,24.62-23.08q4.44-6.87,8.33-14.07a207.39,207.39,0,0,0,13.6-31C465.36,287.13,455.34,221.14,448.9,187.78Z" />
                            <path d="M286.4,302.8l-61.33-46a4,4,0,0,0-2.4-.8h-29.1a3.78,3.78,0,0,1-2.68-1.11l-13.72-13.72a4,4,0,0,0-2.83-1.17H121.15a3.79,3.79,0,0,1-2.68-6.47l8.42-8.42a3.78,3.78,0,0,1,2.68-1.11h32.37a8,8,0,0,0,7.7-5.83l6.89-24.5a4,4,0,0,1,2-2.47L206,177.06a3.79,3.79,0,0,0,2.05-3.37v-12.5a3.82,3.82,0,0,1,.68-2.17L223.33,138a3.75,3.75,0,0,1,1.78-1.38l20.43-7.67a3.79,3.79,0,0,0,2.46-3.55V114a3.8,3.8,0,0,0-1.69-3.16L225.83,97.22A3.83,3.83,0,0,0,222,97l-27.88,13.94a3.78,3.78,0,0,1-4-.41L176.9,100.08a3.8,3.8,0,0,1,.1-6l10.74-7.91a3.78,3.78,0,0,0-.09-6.16L170.92,68.34a3.78,3.78,0,0,0-4-.22c-6.05,3.31-23.8,13.11-30.1,17.52a209.48,209.48,0,0,0-68.16,80c-1.82,3.76-4.07,7.59-4.29,11.72s-3.46,13.35-4.81,17.08a3.78,3.78,0,0,0,.24,3.1l35.69,65.58a3.74,3.74,0,0,0,1.38,1.44l37.55,22.54a3.78,3.78,0,0,1,1.81,2.73l7.52,54.54a3.82,3.82,0,0,0,1.61,2.61l29.3,20.14a4,4,0,0,1,1.65,2.48l15.54,73.8a3.6,3.6,0,0,0,.49,1.22c1.46,2.36,7.28,11,14.3,12.28-.65.18-1.23.59-1.88.78a47.63,47.63,0,0,1,5,1.16c2,.54,4,1,6,1.43,3.13.62,3.44,1.1,4.94-1.68,2-3.72,4.29-5,6-5.46a3.85,3.85,0,0,0,2.89-2.9l10.07-46.68a4,4,0,0,1,1.6-2.42l45-31.9a4,4,0,0,0,1.69-3.27V306A4,4,0,0,0,286.4,302.8Z" />
                            <path d="M262,48s-3.65.21-4.39.23q-8.13.24-16.22,1.12A207.45,207.45,0,0,0,184.21,64c2.43,1.68-1.75,3.22-1.75,3.22L189,80h35l24,12,21-12Z" />
                            <path d="M354.23,120.06l16.11-14a4,4,0,0,0-.94-6.65l-18.81-8.73a4,4,0,0,0-5.3,1.9l-7.75,16.21a4,4,0,0,0,1.49,5.11l10.46,6.54A4,4,0,0,0,354.23,120.06Z" />
                            <path d="M429.64,140.67l-5.83-9c-.09-.14-.17-.28-.25-.43-1.05-2.15-9.74-19.7-17-26.51-5.45-5.15-7-3.67-7.43-2.53a3.77,3.77,0,0,1-1.19,1.6L369.1,127.11a4,4,0,0,1-2.51.89H351.66a4,4,0,0,0-2.83,1.17l-12,12a4,4,0,0,0,0,5.66l12,12a4,4,0,0,0,2.83,1.17h75.17a4,4,0,0,0,4-4.17l-.55-13.15A4,4,0,0,0,429.64,140.67Z" />
                            <path d="M256,72a184,184,0,1,1-130.1,53.9A182.77,182.77,0,0,1,256,72m0-40C132.3,32,32,132.3,32,256S132.3,480,256,480,480,379.7,480,256,379.7,32,256,32Z" />
                        </svg>
                        <div className={clsx({
                        [classes.langMenu]: true,
                        [classes.langMenuActive]: langMenu
                        })}>
                        {langs.map((el, i) => (
                            <div key={i} onClick={e => {
                            e.stopPropagation()
                            setLang(el);
                            setLangMenu(false)
                            }} className={clsx({
                            [classes.langMenuItem]: true,
                            [classes.langMenuItemSelected]: lang === langsMap[el]
                            })}>{el}</div>
                        ))}
                        </div>
                    </div>

                    <div className={classes.burgerWrap}>
                        <div onClick={() => setOpen(!open)} className={classes.burger}>
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;