import React from "react";
import robot from "./Assets/icons/robot.svg";
import talk from "./Assets/icons/talk.svg";
import puzzle from "./Assets/icons/puzzle.svg";
import mind from "./Assets/icons/mind.svg";

export default {
    nav: {
        why: "Почему робототехника?",
        us: "Почему именно мы?",
        start: "С чего начать?",
        events: "Программа",
        acknowledgements: "При поддержке",
        home: 'Главная страница',
    },
    hero: {
        h1: "Будущее за роботами",
        p: "Начните свой путь в будущее с нашим подробным курсом по робототехнике",
    },
    why: {
        h1: "Почему робототехника?",
        items: [
            {
                icon: robot,
                title: "Молодая наука",
                text: `Становление робототехники началось совсем недавно: она нуждается в инноваторах. В мире профессий будущего крайне востребованными будут именно специалисты по робототехнике.`
            },
            {
                icon: talk,
                title: "Вклад в развитие",
                text: `Робототехника - наука для жизни. Каждое изобретение находит свое практическое применение, меняя повседневную жизнь людей в лучшую сторону.`
            },
            {
                icon: puzzle,
                title: "Soft skills",
                text: `Робототехника учит общаться с людьми. Навыки презентации, умение работать в команде, сотрудничать и договариваться приведут вас и вашего робота к вершине олимпа.`
            },
            {
                icon: mind,
                title: "Теория и практика",
                text: `Только в робототехнике наука идет рука об руку с ремеслом будь то шлифовка деталей или создание нейронной сети.`
            }
        ]
    },
    whyus: {
        h1: "Почему мы?",
        h3_1: "Полностью бесплатно",
        p1: "Никаких подписок, скрытых платежей или комиссий.",
        h3_2: "Уникальность знаний",
        p2: "Материалы курса не найти на русском или казахском языках в Интернете.",
        h3_3: "Учитесь у экспертов",
        p3: "Авторы курса - выдающиеся первопроходцы Казахстана на WRO, завоевавшие множество медалей и наград.",
        h3_4: "Казахоязычный контент",
        p4: "Все материалы курса дублируются на казахском языке. Видеоуроки так же выпускаются с казахскими субтитрами.",
    },
    team: {
        h1: "Наша команда",
    },
    start: {
        h1: "С чего начать?",
        h3: "Американские уголки",
        li1: (<> Изучи материал и видеоуроки на платформе <a href="https://school.bc-pf.org">Школы</a></>),
        li2: "Практикуйся в Американском уголке или Maker Space своего города",
        li3: "Обратись к наставникам Американских уголков",
        cities1: ["Нур-Султан", "Павлодар", "Петропавловск", "Усть-Каменогорск", "Караганда"],
        cities2: ["Алматы", "Шымкент", "Актобе", "Атырау", "Уральск"],
    },
    timeline: {
        h1: "Программа",
    },
    webinars: {
        h1: "Вебинары",
        about: "О спикере",
        topic: "Тема",
        but: "Зарегистрироваться",
        connect: "Подключиться к вебинару",
        watch: "Запись вебинара"
    },
    posts: {
        h1: "Статьи в Блоге",
        empty: "Ожидайте обновлений! "
    },
    wiki: {
        h1: "Руководства в Wiki",
        empty: "Ожидайте обновлений! :)"
    },
    footer: {
        h1: "При поддержке",
        funding: "Данный проект разработан при поддержке государственно-частного партнерства “Шеврон” и Посольства США в Казахстане. "
    } 

}